export interface IFontInfo {
  fontFamily: FontFamilyType;
  fontName: string;
  fontWeight: number;
}

export const FONT_FAMILY = ['Pretendard', 'Noto Sans KR', 'NanumSquare', 'NanumGothic', 'NanumMyeongjo'];
export type FontFamilyType = (typeof FONT_FAMILY)[number];

export const FONT_FAMILY_GROUP: Record<FontFamilyType, Omit<IFontInfo, 'fontFamily'>[]> = {
  Pretendard: [
    { fontName: 'Pretendard-Light', fontWeight: 300 },
    { fontName: 'Pretendard-Regular', fontWeight: 400 },
    { fontName: 'Pretendard-Medium', fontWeight: 500 },
    { fontName: 'Pretendard-Bold', fontWeight: 700 },
  ],
  ['Noto Sans KR']: [
    { fontName: 'NotoSansKR-Light', fontWeight: 300 },
    { fontName: 'NotoSansKR-Regular', fontWeight: 400 },
    { fontName: 'NotoSansKR-Medium', fontWeight: 500 },
    { fontName: 'NotoSansKR-Bold', fontWeight: 700 },
  ],
  NanumSquare: [
    { fontName: 'NanumSquare_acL', fontWeight: 400 },
    { fontName: 'NanumSquare_acR', fontWeight: 500 },
    { fontName: 'NanumSquare_acB', fontWeight: 700 },
    { fontName: 'NanumSquare_acEB', fontWeight: 800 },
  ],
  NanumGothic: [
    { fontName: 'NanumGothicLight', fontWeight: 400 },
    { fontName: 'NanumGothic', fontWeight: 500 },
    { fontName: 'NanumGothicBold', fontWeight: 700 },
    { fontName: 'NanumGothicExtraBold', fontWeight: 800 },
  ],
  NanumMyeongjo: [
    { fontName: 'NanumMyeongjo', fontWeight: 400 },
    { fontName: 'NanumMyeongjoBold', fontWeight: 500 },
    { fontName: 'NanumMyeongjoExtraBold', fontWeight: 800 },
  ],
};
