import EditorIcon from '../../EditorIcon';
import { ReactEditor, useSlate } from 'slate-react';
import { FC, useEffect, useRef, useState } from 'react';
import Icon from '../../../icon/Icon';
import { BaseEditor, Editor } from 'slate';
import classnames from 'classnames/bind';
import style from './ToolbarButton.module.scss';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import { COLORS } from '../../../../constants/colors';
import type { MarkFormatType, ShowPopType } from '../../textEditor.constant';
import Tab from '../../../tab/Tab';
import TextFieldBase from '../../../textfield/TextFieldBase';
import {
  HIDE_TYPE,
  IFolderLNBType,
  ILNBType,
  ILNBTypes,
  ISingleLNBType,
  URL_TYPE,
  UrlType,
} from '../../../../interface/header/IMenu';
import Button from '../../../button/Button';
import Checkbox from '../../../checkbox/Checkbox';
import usePageDropdown from '../../../../hook/editor/usePageDropdown';
import { delay } from '../../../../util/time';

const cx = classnames.bind(style);

const isMarkActive = (editor: BaseEditor & ReactEditor, format: MarkFormatType) => {
  const marks = Editor.marks(editor);
  return marks ? (marks as any)[format] : false;
};

interface IProps {
  setShowPop: (v: ShowPopType) => void;
  showPop: ShowPopType;
  pageList?: ILNBType<ILNBTypes>[];
}

const HyperLinkButton: FC<IProps> = ({ showPop, setShowPop, pageList = [] }) => {
  const editor = useSlate();
  const isActive = isMarkActive(editor, 'link') || isMarkActive(editor, 'pageLink');
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [type, setType] = useState<'PAGE' | 'URL'>('PAGE');
  const { PageDropdown, setShowDropdown, tempPage, setTempPage } = usePageDropdown({ pageList });

  useEffect(() => {
    setIsValidUrl(!!url);
  }, [url]);

  useOnClickOutside(ref, (e) => {
    showPop === 'URL' && setShowPop(null);
    setUrl('');
    setShowDropdown(false);
    setTempPage(null);
    setType('PAGE');
  });

  useEffect(() => {
    (async () => {
      if (showPop === 'URL' && type === 'URL' && inputRef.current) {
        const input = inputRef.current;
        await delay(0);
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    })();
  }, [showPop, type]);

  useEffect(() => {
    if (isActive && typeof isActive === 'object') {
      const { type, lnb } = isActive;
      setTempPage({ ...lnb });
      setType(type);
      setUrl(lnb?.page?.url ?? '');
    }

    /** 하위호환 작업 */
    if (isActive && typeof isActive === 'string') {
      setType('URL');
      setUrl(isActive);
      setTempPage({
        title: '',
        formType: 'SINGLE',
        page: { urlType: URL_TYPE.EXTERNAL, url: isActive },
      } as ILNBType<ISingleLNBType>);
    }
  }, [isActive]);

  return (
    <button
      className={cx('icon-button', 'hyper', isActive && 'active')}
      type={'button'}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isActive) {
          setShowPop('URL');
        } else {
          setShowPop(showPop === 'URL' ? null : 'URL');
        }
      }}
    >
      <EditorIcon name={'link'} />

      {showPop === 'URL' && (
        <div className={cx('hyper-wrapper')} onMouseDown={(e) => e.stopPropagation()} ref={ref}>
          <Tab
            className={cx('tab')}
            type={'secondary'}
            onChange={(v) => setType(v)}
            options={[
              { value: 'PAGE', label: '페이지' },
              { value: 'URL', label: 'URL' },
            ]}
            value={type}
          />
          <div className={cx('bottom')}>
            {type === 'PAGE' ? (
              <div className={cx('dropdown-container')}>
                <PageDropdown />
                <Button
                  className={cx('save')}
                  onClick={() => {
                    if (tempPage) {
                      Editor.addMark(editor, 'pageLink', { type, lnb: tempPage });
                    } else {
                      Editor.removeMark(editor, 'link');
                      Editor.removeMark(editor, 'pageLink');
                    }
                    setTempPage(null);
                    setShowPop(null);
                  }}
                >
                  저장
                </Button>
              </div>
            ) : (
              <div className={cx('url-wrapper')}>
                <TextFieldBase
                  size={'md'}
                  placeholder={'URL을 입력하세요.'}
                  className={cx('input')}
                  value={url}
                  onChange={(e) => {
                    if (!e.target.value) {
                      Editor.removeMark(editor, 'link');
                      Editor.removeMark(editor, 'pageLink');
                    }

                    setUrl(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && isValidUrl) {
                      let addMarkUrl = url;
                      if (!/^https?:\/\//i.test(addMarkUrl)) {
                        addMarkUrl = 'https://' + url;
                      }
                      Editor.addMark(editor, 'pageLink', {
                        type,
                        lnb: {
                          page: {
                            url: addMarkUrl,
                            urlType: URL_TYPE.EXTERNAL,
                          },
                        },
                      });
                      setShowPop(null);
                      setUrl('');
                    }
                  }}
                  ref={inputRef}
                />
                <Button
                  className={cx('url-button')}
                  onClick={() => {
                    if (!isValidUrl) return;

                    let addMarkUrl = url;
                    if (!/^https?:\/\//i.test(addMarkUrl)) {
                      addMarkUrl = 'https://' + url;
                    }

                    Editor.addMark(editor, 'pageLink', {
                      type,
                      lnb: {
                        page: {
                          url: addMarkUrl,
                          urlType: URL_TYPE.EXTERNAL,
                        },
                      },
                    });
                    setShowPop(null);
                    setUrl('');
                  }}
                >
                  저장
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </button>
  );
};

export default HyperLinkButton;
