// Created by kdw0601 on 2022-12-13
import styles from './IconButton.module.scss';
import classNames from 'classnames/bind';
import { ButtonHTMLAttributes } from 'react';
import IconElement, { IconTypes } from '../icon/Icon';
import { Stepper } from './IconButtonVariants/Stepper/Stepper';
import { SwipeArrow } from './IconButtonVariants/SwipeArrow/SwipeArrow';
import { COLORS } from '../../constants/colors';

const cx = classNames.bind(styles);

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  variants?: 'ghost' | 'negative' | 'tealGhost';
  icon: IconTypes;
  size?: 18 | 24 | 32 | 40 | 48;
  color?: string;
}

// TODO: teal ghost 고도화
const IconButton = (props: IProps) => {
  const {
    variants = 'ghost',
    icon,
    color = COLORS.gray500,
    className,
    size = 32,
    onClick,
    onMouseDown,
    ...htmlAttr
  } = props;
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (htmlAttr.disabled) {
      e.stopPropagation();
      return;
    }

    onMouseDown?.(e);
    onClick?.(e);
  };

  return (
    <button
      type={'button'}
      onMouseDown={handleClick}
      style={{ width: size, height: size, fill: color }}
      className={cx('button', className, variants)}
      {...htmlAttr}
    >
      <IconElement name={icon} size={size} color={color} />
    </button>
  );
};

export default Object.assign(IconButton, { Stepper, SwipeArrow });
