import { CustomText, FontSizeType, FontType, TypicalFontSizeType } from './textEditor.type';

export const MARK_FORMAT = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  STRIKE_THROUGH: 'strikethrough',
  HIGHLIGHT: 'highlight',
  COLOR: 'color',
  LINK: 'link',
  PAGE_LINK: 'pageLink',
  FONT_SIZE: 'fontSize',
} as const;

export const SHOW_POP = {
  COLOR_PICKER: 'COLOR_PICKER',
  FONT_TYPE: 'FONT_TYPE',
  FONT_FAMILY: 'FONT_FAMILY',
  FONT_SIZE: 'FONT_SIZE',
  color: 'color',
  highlight: 'highlight',
  URL: URL,
} as const;

export const BLOCK_BUTTON_SIZE = {
  S: '5px 16px',
  M: '9px 16px',
  L: '16px 35px',
};

export type MarkFormatKeyType = keyof typeof MARK_FORMAT;
export type MarkFormatType = (typeof MARK_FORMAT)[MarkFormatKeyType];
export type ShowPopType = keyof typeof SHOW_POP | null;

/// font
export const FONT_TYPE_LIST: {
  title: string;
  value: FontType;
  size: TypicalFontSizeType;
  bold: boolean;
}[] = [
  { title: '제목1', value: 'title1', size: 64, bold: true },
  { title: '제목2', value: 'title2', size: 48, bold: true },
  { title: '제목3', value: 'title3', size: 40, bold: true },
  { title: '제목4', value: 'title4', size: 32, bold: true },
  { title: '제목5', value: 'title5', size: 24, bold: true },
  { title: '본문1', value: 'content1', size: 20, bold: false },
  { title: '본문2', value: 'content2', size: 16, bold: false },
  { title: '본문3', value: 'content3', size: 14, bold: false },
  { title: '본문4', value: 'content4', size: 12, bold: false },
];

export const FONT_SIZE_TO_FONT_TYPE: Record<TypicalFontSizeType, FontType> = {
  64: 'title1',
  48: 'title2',
  40: 'title3',
  32: 'title4',
  24: 'title5',
  20: 'content1',
  16: 'content2',
  14: 'content3',
  12: 'content4',
};

export const FONT_TYPE_TO_FONT_SIZE: Record<FontType, FontSizeType> = {
  title1: 64,
  title2: 48,
  title3: 40,
  title4: 32,
  title5: 24,
  content1: 20,
  content2: 16,
  content3: 14,
  content4: 12,
};

export const DEFAULT_FONT_TEXT = '';
export const DEFAULT_FONT_SIZE: FontSizeType = 20;
export const DEFAULT_FONT_TYPE: FontType = FONT_SIZE_TO_FONT_TYPE[DEFAULT_FONT_SIZE];

export const getInitialEditorText = (props?: Partial<CustomText>): CustomText => {
  const getFontTypeFromSize = (): FontType => {
    if (!props) return DEFAULT_FONT_TYPE;
    if (props.fontType) return props.fontType;
    return FONT_SIZE_TO_FONT_TYPE[props.fontSize as TypicalFontSizeType] ?? DEFAULT_FONT_TYPE;
  };

  const {
    text = DEFAULT_FONT_TEXT,
    fontSize = DEFAULT_FONT_SIZE,
    fontType = getFontTypeFromSize(),
    ...other
  } = props || {};
  return {
    text,
    fontSize,
    fontType,
    ...other,
  };
};
