import React from 'react';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import classnames from 'classnames/bind';
import EditorIcon from '../../EditorIcon';

import style from './ToolbarButton.module.scss';
import { MarkFormatType } from '../../textEditor.constant';
import { isMarkActive } from '../../textEditor.util';

const cx = classnames.bind(style);

const toggleMark = (editor: BaseEditor & ReactEditor, format: MarkFormatType) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const MarkButton = ({ format }: { format: MarkFormatType }) => {
  const editor = useSlate();

  return (
    <button
      className={cx('container', { active: isMarkActive(editor, format) })}
      type={'button'}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <EditorIcon name={format} />
    </button>
  );
};

export default MarkButton;
