import { FC, useRef } from 'react';
import { BlockFormatType } from '../../textEditor.type';
import style from './ToolbarButton.module.scss';
import classnames from 'classnames/bind';
import Icon from '../../../icon/Icon';
import { COLORS } from '../../../../constants/colors';
import useToast from '../../../../context/toast/useToast';
import { postFileUpLoadV1 } from 'ats-editor/src/api/common/file';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { getImageURL } from 'ats-editor/src/utils/common/file.utils';
import useLoading from 'ui/context/loading/useLoading';
import { getInitialEditorText } from '../../textEditor.constant';

const cx = classnames.bind(style);

const FILE_MAX_SIZE = 1048576 * 20;
const IMG_EXE = ['image/png', 'image/svg+xml', 'image/jpeg', 'image/gif', 'image/webp'];

const ImageUploadButton: FC<{ format: BlockFormatType }> = ({ format }) => {
  const editor = useSlate();
  const { setToast, setFailToast } = useToast();
  const { setLoadingWhileLongTask } = useLoading();

  const imageRef = useRef<HTMLInputElement>(null);

  const handlerSlateFileUpload = async ({ file }: { file?: File }) => {
    if (!file) return;

    if (file.size > FILE_MAX_SIZE) {
      setToast({ icon: 'FAILED', text: '이미지는 50MB까지 등록할 수 있습니다' });
      return;
    }
    if (!IMG_EXE.includes(file.type)) {
      setToast({ icon: 'FAILED', text: '이미지만 업로드 가능합니다.' });
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      const { fileUid, fileUrl } = await postFileUpLoadV1(formData);

      ReactEditor.focus(editor);
      Transforms.insertNodes(editor, {
        type: 'image',
        imageUrl: getImageURL({ fileUid, fileUrl }) ?? '',
        label: file.name,
        imageStyle: {
          borderRadius: 0,
          align: 'left',
        },
        children: [getInitialEditorText()],
      });
      Transforms.insertNodes(editor, {
        type: 'paragraph',
        children: [getInitialEditorText()],
      });
    } catch (e) {
      setFailToast();
      console.error(e);
    } finally {
      if (imageRef.current) {
        imageRef.current.value = '';
      }
    }
  };

  return (
    <button className={cx('container')} type={'button'} onClick={() => imageRef.current && imageRef.current.click()}>
      <Icon name={'photo_filled'} color={COLORS.gray800} size={24} />
      <input
        type={'file'}
        ref={imageRef}
        className={cx('image-file-upload')}
        onChange={async (e) => {
          // setLoadingWhileLongTask(() => handlerSlateFileUpload({ file: e.target.files?.[0] }));
          await handlerSlateFileUpload({ file: e.target.files?.[0] });
        }}
      />
    </button>
  );
};

export default ImageUploadButton;
