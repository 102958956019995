import React, { useRef } from 'react';
import { Saturation } from './common/saturation/Saturation';
import classNames from 'classnames/bind';
import style from './ColorPicker.module.scss';
import { IColorModel, IColorPickerBaseProps, IRgbaColor } from './types';
import { hsvaToRgba, rgbaToHsva } from './utils/convert';
import { equalColorObjects } from './utils';
import { useColorManipulation } from './hooks/useColorManipulation';
import { Hue } from './common/hue/Hue';
import { Alpha } from './common/alpha/Alpha';
import ColorInput from './colorInput/ColorInput';
import Palette from './palette/Palette';
const cx = classNames.bind(style);

const colorModel: IColorModel<IRgbaColor> = {
  defaultColor: { r: 0, g: 0, b: 0, a: 1 },
  toHsva: rgbaToHsva,
  fromHsva: hsvaToRgba,
  equal: equalColorObjects,
};

const ColorPicker = ({ color, onChange, ...rest }: IColorPickerBaseProps<IRgbaColor>) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  const [hsva, updateHsva] = useColorManipulation(colorModel, color, onChange);

  return (
    <div {...rest} ref={nodeRef} className={cx('area')}>
      <Saturation hsva={hsva} onChange={updateHsva} />

      <div className={cx('controlArea')}>
        <div className={cx('controlWrap')}>
          <Hue hue={hsva.h} onChange={updateHsva} />
          <Alpha hsva={hsva} onChange={updateHsva} />
        </div>

        <div className={cx('colorInputWrap')}>
          <ColorInput color={color} onChange={onChange} />
        </div>

        {/*<BrandColor />*/}

        <Palette color={color} onChange={onChange} />
      </div>
    </div>
  );
};

export default ColorPicker;
