import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { CustomElement } from '../../textEditor.type';

const useSettingPopupTextButton = () => {
  const handleButtonStyle = <StyleType>(editor: ReactEditor, element: CustomElement, newStyle: Partial<StyleType>) => {
    const { style, ...rest } = element as CustomElement;

    Transforms.setNodes(editor, {
      ...rest,
      style: {
        ...style,
        ...newStyle,
      },
    });
  };

  const handleButtonValue = <ValueType>(editor: ReactEditor, element: CustomElement, newValue: Partial<ValueType>) => {
    const { ...rest } = element as CustomElement;

    Transforms.setNodes(editor, {
      ...rest,
      ...newValue,
    });
  };

  return { handleButtonStyle, handleButtonValue };
};

export default useSettingPopupTextButton;
