import React, { FC, useRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { BaseEditor, Editor } from 'slate';
import classnames from 'classnames/bind';

import style from './ToolbarButton.module.scss';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import Icon from '../../../icon/Icon';
import { COLORS } from '../../../../constants/colors';
import { FONT_SIZE, FontSizeType, IFont, TYPICAL_FONT_SIZE, TypicalFontSizeType } from '../../textEditor.type';
import { DEFAULT_FONT_SIZE, FONT_SIZE_TO_FONT_TYPE } from '../../textEditor.constant';

const cx = classnames.bind(style);

const FontSizeButton: FC<IFont> = ({ fontSize, setFontSize, setFontType, showPop, setShowPop }) => {
  const editor = useSlate();
  const fontSizeRef = useRef<HTMLButtonElement>(null);

  const toggleFontSize = (fontSize: FontSizeType = DEFAULT_FONT_SIZE) => {
    setFontSize(fontSize);
    Editor.addMark(editor, 'fontSize', fontSize);
    if (TYPICAL_FONT_SIZE.includes(fontSize as TypicalFontSizeType)) {
      setFontType(FONT_SIZE_TO_FONT_TYPE[fontSize as TypicalFontSizeType]);
      Editor.addMark(editor, 'fontType', FONT_SIZE_TO_FONT_TYPE[fontSize as TypicalFontSizeType]);
    }
  };

  useOnClickOutside(fontSizeRef, () => {
    showPop === 'FONT_SIZE' && setShowPop(null);
  });

  return (
    <button
      className={cx('container', 'select-fontSize')}
      type={'button'}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => {
        setShowPop(showPop === 'FONT_SIZE' ? null : 'FONT_SIZE');
      }}
      ref={fontSizeRef}
    >
      {showPop === 'FONT_SIZE' && (
        <div className={cx('popover-wrapper', 'font-size')}>
          <ul className={cx('popover-container')}>
            {FONT_SIZE.map((size) => (
              <li
                className={cx({ active: fontSize === size })}
                key={size}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  toggleFontSize(size);
                  setShowPop(null);
                }}
              >
                {size}
              </li>
            ))}
          </ul>
        </div>
      )}
      <span>{fontSize}</span>
      <Icon name={'arrow_drop_down_filled'} size={24} color={COLORS.gray600} />
    </button>
  );
};

export default FontSizeButton;
