import { FC, InputHTMLAttributes } from 'react';
import classnames from 'classnames/bind';
import style from './Option.module.scss';
import useRadioContext from './useRadioGroupContext';

const cx = classnames.bind(style);

export interface IRadioGroupOptionProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: any;
}

const Option: FC<IRadioGroupOptionProps> = ({ id, disabled, value, children }) => {
  const { name, onChange, setSelectValue } = useRadioContext();

  const htmlFor = `${id ?? ''} + ${name} + ${value}`;

  return (
    <label className={cx('container')} htmlFor={htmlFor}>
      <input
        id={htmlFor}
        type="radio"
        onChange={(e) => {
          setSelectValue(e.target.value);
          onChange && onChange(e);
        }}
        name={name}
        disabled={disabled}
        value={value}
      />
      <span className={cx('render')}>{children}</span>
    </label>
  );
};

export default Option;
