import Portal from 'ui/common/portal/Portal';
import classNames from 'classnames/bind';
import style from './index.module.scss';
import { FC, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'ui/hook/useOnClickOutside';
import { IColorPickerBaseProps, IRgbaColor } from 'ui/common/colorPicker/types';
import ColorPicker from 'ui/common/colorPicker/ColorPicker';
const cx = classNames.bind(style);

type ColorPickerModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
} & IColorPickerBaseProps<IRgbaColor>;

const ColorPickerModal: FC<ColorPickerModalProps> = ({ isVisible, setIsVisible, color, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);
  const positionRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<{ top?: number; left?: number }>({});

  useOnClickOutside(ref, () => {
    setIsVisible(false);
  });

  useEffect(() => {
    if (!positionRef.current) return;

    // TODO 모달을 띄울 기준점을 바꾸고 싶으면, window size 를 구해서, 위치를 조절하는 로직을 추가해주세요. - 현재 기준: 오른쪽 상단
    setPosition({
      top: positionRef.current?.getBoundingClientRect().top,
      left: positionRef.current?.getBoundingClientRect().left - 652,
    });
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <>
      <Portal domId={'color-picker-root'}>
        <div
          ref={ref}
          className={cx('modalArea')}
          style={{
            top: position.top,
            left: position.left,
          }}
        >
          <ColorPicker color={color} onChange={onChange} />
        </div>
      </Portal>
      <div ref={positionRef} />
    </>
  );
};

export default ColorPickerModal;
