import { createContext, InputHTMLAttributes } from 'react';

export const RadioGroupContext = createContext<
  InputHTMLAttributes<HTMLInputElement> & { selectValue: string; setSelectValue: (v: string) => void }
>({
  name: '',
  selectValue: '',
  setSelectValue: () => {
    //
  },
});
