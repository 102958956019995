import { useContext } from 'react';
import GlobalContext from 'ui/context/GlobalContext';

const useLoading = () => {
  const {
    loading: { loading, setLoading },
  } = useContext(GlobalContext);

  const setLoadingWhileLongTask = async (
    callback: () => Promise<void> | void,
    callbackAfterLoading?: () => Promise<void> | void
  ) => {
    try {
      setLoading(true);
      await callback();
      setLoading(false);
      await callbackAfterLoading?.();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return { loading, setLoading, setLoadingWhileLongTask };
};

export default useLoading;
