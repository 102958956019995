import { useContext } from 'react';
import { RadioGroupContext } from './RadioGroupContext';

const useRadioContext = () => {
  const context = useContext(RadioGroupContext);
  if (context === undefined) {
    throw new Error('must used within ');
  }
  return context;
};

export default useRadioContext;
