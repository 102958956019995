import { IGetArticleDetailResponse } from 'ui/interface/contents/board/getArticleDetailPreview';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { getArticleDetailV1, getThumbnailPostListV1 } from '../../api/post/post';
import { AxiosError } from 'axios';
import {
  IPostThumbnailPostListV1Request,
  IPostThumbnailPostListV1Response,
} from 'ui/interface/contents/board/getThumbnailPostListV1';

export const PostQueryKey = {
  all: ['post'] as const,
  detail: (articleSn: number) => [...PostQueryKey.all, articleSn] as const,
};

// 게시글 목록 조회
export const useMutationPostThumbnailPostList = (): UseMutationResult<
  IPostThumbnailPostListV1Response,
  AxiosError,
  IPostThumbnailPostListV1Request
> => {
  return useMutation({
    mutationFn: getThumbnailPostListV1,
  });
};

// 게시글 상세 미리보기 조회
export const useQueryGetArticlePreview = (request: {
  articleSn: number;
  prefix?: string;
}): UseQueryResult<IGetArticleDetailResponse, AxiosError> => {
  return useQuery({
    queryKey: [...PostQueryKey.detail(request.articleSn)],
    queryFn: () => getArticleDetailV1(request),
  });
};
