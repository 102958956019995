import { useEffect, useRef, useState } from 'react';
import useWindowResize from '../../hook/useWindowResize';

const useSwipe = (enabled = false) => {
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { width: windowWidth } = useWindowResize();

  const scrollToRight = (_moveValue?: number) => scroll('right', _moveValue);
  const scrollToLeft = (_moveValue?: number) => scroll('left', _moveValue);
  const scroll = (type: 'left' | 'right', _moveValue?: number) => {
    if (!targetRef.current) return;
    const { clientWidth, scrollWidth } = targetRef.current;
    const minScrollPosition = clientWidth - scrollWidth;
    const maxScrollPosition = 0;

    const moveValue = _moveValue ?? Math.max(clientWidth - 100, 100);
    const _newScrollPosition = scrollPosition + (type === 'left' ? moveValue : -moveValue);
    const newScrollPosition = Math.max(minScrollPosition, Math.min(_newScrollPosition, maxScrollPosition));
    setScrollPosition(newScrollPosition);

    setTimeout(() => {
      setShowBackArrow(newScrollPosition < maxScrollPosition);
      setShowNextArrow(newScrollPosition > minScrollPosition);
    }, 300);
  };

  useEffect(() => {
    if (!enabled) return;
    scroll('left', 0);
  }, [enabled, targetRef?.current, windowWidth]);

  return {
    showBackArrow,
    showNextArrow,
    scrollToRight,
    scrollToLeft,
    targetRef,
    scrollPosition,
  };
};

export default useSwipe;
