import { ButtonHTMLAttributes } from 'react';
import styles from './SwipeArrow.module.scss';
import classNames from 'classnames/bind';
import IconElement from '../../../icon/Icon';

const cx = classNames.bind(styles);

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  arrowType: 'back' | 'next';
  variant?: 'ghost' | 'fill' | 'outlined' | 'blur';
  isFocused?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const SwipeArrow = ({
  arrowType,
  variant = 'ghost',
  size = 'sm',
  isFocused,
  disabled,
  className,
  ...htmlAttr
}: IProps) => {
  return (
    <button
      className={cx('swipe-arrow', variant, size, { isFocused: isFocused }, className)}
      disabled={disabled}
      {...htmlAttr}
    >
      <IconElement name={arrowType === 'back' ? 'back_line' : 'next_line'} className={cx('indicator__svg')} size={18} />
    </button>
  );
};

/* EXAMPLE
 * <IconButton.SwipeArrow arrowType="back" size="lg" isFocused/>
 * <IconButton.SwipeArrow arrowType="next" variant="ghost" disabled size="md" />
 */

/* NOTE
 * back은 왼쪽을 향하는 화살표, next는 오른쪽을 향하는 화살표 (필수갑)
 * variant는 기본적으로 ghost, 옵셔널로 다른 variatnt를 원할 경우 설정해준다.
 * size는 sm, md, lg 중 하나로, 기본적으로 아무것도 할당해주지 않을경우 sm으로 적용된다.
 * isFocused는 SwipeArrow가 포커스된 상태인지 (구체적으로 어떤 상황에 적용되는지는 모르겠지만 디자인키트에 존재)
 * disabled는 SwipeArrow를 비활성화시키는 것. isFocused와 disabled가 함께 설정되어 있다면 isFocused가 씹히고 disabled 스타일만 남는다.
 * className을 props로 받았기 때문에, 부모가 SwipeArrow의 스타일링을 할 수 있다. 특히, 위치 설정할 때 이를 활용하도록 한다.
 */
