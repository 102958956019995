import style from './CustomButtonToolbar.module.scss';
import classnames from 'classnames/bind';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';
import { FC, startTransition, useRef, useState } from 'react';
import { COLORS } from '../../../../constants/colors';
import { hexToRgba, rgbaToHex } from '../../../colorPicker/utils/convert';
import Icon from '../../../icon/Icon';
import Button from '../../../button/Button';
import ColorPicker from '../../../colorPicker/ColorPicker';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import { IRgbaColor } from '../../../colorPicker/types';
import { DEFAULT_FONT_SIZE, DEFAULT_FONT_TYPE, getInitialEditorText } from '../../textEditor.constant';

const cx = classnames.bind(style);

interface IProps {
  backgroundColor: string;
  onChangeBgColor?: (background: string) => void;
}

const CustomButtonToolbar: FC<IProps> = ({ backgroundColor, onChangeBgColor }) => {
  const editor = useSlate();
  const ref = useRef<HTMLDivElement>(null);
  const [showBackground, setShowBackground] = useState(false);
  useOnClickOutside(ref, () => {
    setShowBackground(false);
  });

  const ButtonBlock = () => {
    ReactEditor.focus(editor);
    Transforms.insertNodes(
      editor,
      {
        type: 'button',
        children: [getInitialEditorText({ text: '텍스트를 입력해주세요' })],
        size: 'S',
        style: {
          backgroundColor: COLORS.violet600,
          borderRadius: 4,
          color: COLORS.white,
        },
      },
      { at: [editor.children.length] }
    );
    Transforms.insertNodes(
      editor,
      {
        type: 'paragraph',
        children: [getInitialEditorText()],
      },
      { at: [editor.children.length] }
    );
  };

  const handleChangeColor = (val: IRgbaColor) => {
    startTransition(() => {
      onChangeBgColor && onChangeBgColor(rgbaToHex(val));
    });
  };

  return (
    <div className={cx('container')}>
      <Button
        className={cx('toolbar-button')}
        leftIcon={'add_line'}
        variant={'outlined'}
        size={'sm'}
        onClick={ButtonBlock}
      >
        버튼 추가
      </Button>
      <div className={cx('picker-wrapper')} ref={ref}>
        <Button
          variant={'outlined'}
          size={'sm'}
          left={<Icon name={'brand_color_lined'} size={18} color={COLORS.white} />}
          onClick={() => {
            setShowBackground(!showBackground);
          }}
        >
          <div>배경 색상</div>
        </Button>

        {showBackground && (
          <div className={cx('picker')}>
            <ColorPicker color={hexToRgba(backgroundColor)} onChange={handleChangeColor} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomButtonToolbar;
