import React, { useEffect, useRef, useState } from 'react';
import { RenderElementProps } from 'slate-react/dist/components/editable';
import classnames from 'classnames/bind';
import style from './ImageElement.module.scss';
import { useSelected, useSlate } from 'slate-react';
import RadioGroup from '../../radiogroup/RadioGroup';
import Icon, { IconTypes } from '../../icon/Icon';
import { COLORS } from '../../../constants/colors';
import Paragraph from '../../paragraph/Paragraph';
import TextFieldBase from '../../textfield/TextFieldBase';
import Portal from '../../portal/Portal';
import { isNumberReg } from '../../../util/reg';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { IMAGE_ALIGN, STYLE_WIDTH } from './ImageElement.constant';
import IconButton from '../../button/IconButton';
import { EditorAlign, PREVIEW_WITH_PREVENT_LINK } from '../textEditor.type';
import { postFileCdnUpLoadV1 } from '../../../api/file';
import ImageHyperLinkButton from './ImageHyperLinkButton';
import { ILNBType, ILNBTypes } from '../../../interface/header/IMenu';
import useUrlRouter from '../../../hook/useUrlRouter';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { SOLUTION_TYPE } from '../../../util/solution';
import useWarningToast from '../../../context/toast/useWarningToast';
import { HasOption, PageList } from '../../../interface/common/hasOption';
import { LANGUAGE } from '../../../constants/language';
import { useUrl } from '../../../hook/useUrl';

const cx = classnames.bind(style);

const isActive = (editor: Editor) => {
  if (editor.selection) {
    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, editor.selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image',
    });

    return match;
  }
  return;
};

const TOOLBAR_WIDTH_OFFSET = 760;
const TOOLBAR_HEIGHT_OFFSET = 50;
const TOOLBAR_LEFT_OFFSET = 300;

const ImageElement = ({
  attributes,
  children,
  element,
  pageList,
  isPreview,
  readOnly,
  options,
}: RenderElementProps & { isPreview?: boolean; readOnly?: boolean; pageList: ILNBType<ILNBTypes>[] } & HasOption) => {
  const selected = useSelected();
  const editor = useSlate();
  const { get } = useSearchParams() as ReadonlyURLSearchParams;

  const imageBlock = isActive(editor);

  const [imageType, setImageType] = useState<'S' | 'M' | 'L' | 'FULL' | 'CUSTOM'>('CUSTOM');
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [mounted, setMounted] = useState(false);
  const [cdnUrl, setCdnUrl] = useState('');
  const { handleUrlClick } = useUrlRouter();
  const pageLink = element.pageLink;
  const { setPreviewDisabledWarningToast } = useWarningToast();
  const languageType = options && options['languageType'];
  const { buildUrl } = useUrl();

  useEffect(() => {
    if (!imageRef.current) return;

    const { left, top } = imageRef.current.getBoundingClientRect();
    setTooltipPosition({
      left: window.innerWidth < left + TOOLBAR_WIDTH_OFFSET ? left - TOOLBAR_LEFT_OFFSET : left,
      top: top + window.scrollY - TOOLBAR_HEIGHT_OFFSET,
    });
  }, [imageRef, !!imageBlock, window.scrollY]);

  const imageUrl = element.imageUrl ?? element.url;
  useEffect(() => {
    (async () => {
      if (!imageUrl) return;
      if (imageUrl.startsWith('http')) {
        setCdnUrl(imageUrl);
      } else {
        const { fileUrl } = await postFileCdnUpLoadV1(imageUrl ?? '');
        setCdnUrl(fileUrl);
      }
      setMounted(true);
    })();
  }, [imageUrl]);

  useEffect(() => {
    if (document.querySelector('[data-id="slate-editor"]')) {
      document.querySelector('[data-id="slate-editor"]')?.addEventListener('scroll', handleDeselect);
    }
    return () => {
      document.querySelector('[data-id="slate-editor"]')?.removeEventListener('scroll', handleDeselect);
    };
  }, []);

  const handleDeselect = () => {
    if (isActive(editor)) {
      Transforms.deselect(editor);
    }
  };

  const setImageBlockStyle = (props: {
    width?: number;
    height?: number | string;
    borderRadius?: number;
    align?: EditorAlign;
  }) => {
    Transforms.setNodes(editor, {
      ...element,
      imageStyle: {
        ...element.imageStyle!,
        ...props,
      },
    });
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, style: 'width' | 'height' | 'borderRadius') => {
    if (!isNumberReg(`${e.target.value}`)) {
      setImageBlockStyle({
        [style]: 0,
      });
      return;
    }

    const maxValue = {
      width: 760,
      height: 999,
      borderRadius: 999,
    };

    const value = Number.isNaN(Number(e.target.value))
      ? 0
      : Number(e.target.value) > maxValue[style]
      ? maxValue[style]
      : Number(e.target.value);

    imageRef.current!.style[style] = `${value}px`;

    setImageBlockStyle({
      [style]: value,
    });
  };

  const handleOnChangeAlign = (align: EditorAlign) => {
    setImageBlockStyle({
      align,
    });
  };

  const handleClickImage = (e: React.MouseEvent<HTMLImageElement>) => {
    if (imageBlock) {
      e.stopPropagation();
      handleDeselect();
      return;
    }
    if (!readOnly) return;
    if (!pageLink) return;

    if (Object.values(PREVIEW_WITH_PREVENT_LINK).includes(get('previewType') ?? '')) {
      return setPreviewDisabledWarningToast();
    }

    const isExternal = pageLink?.lnb?.page?.urlType === 'EXTERNAL';
    const { url, pageSn, urlType } = pageLink?.lnb?.page!;

    if (isPreview) {
      return handleUrlClick({
        url: isExternal
          ? url
          : buildUrl('/editor/preview')
              .withSearchParam('designSn', get('designSn'))
              .withSearchParam('pageSn', pageSn)
              .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
              .getResult(),
        isExternal,
        isPreview,
      });
    }

    const pageLinkList = options?.pageLinkList as PageList;
    const internalUrl = pageLinkList?.find((page) => page.pageSn === pageSn)?.urlPath;

    const HOST_NAME = {
      JOBDA: `${location.origin}/${internalUrl}`,
      JOBFLEX: `${location.origin}/career/${internalUrl}`,
    };

    window.open(
      urlType === 'INTERNAL'
        ? buildUrl(HOST_NAME[SOLUTION_TYPE])
            .withSearchParam('languageType', languageType !== LANGUAGE.KOR ? languageType : null)
            .getResult()
        : url,
      '_blank'
    );
  };

  return (
    <div {...attributes} className={cx('container')}>
      {children}
      {!readOnly && selected && !!imageBlock && (
        <Portal domId={'modal-root'}>
          <div
            className={cx('image-util')}
            ref={tooltipRef}
            style={{
              left: `${tooltipPosition.left}px`,
              top: `${tooltipPosition.top}px`,
            }}
          >
            <div className={cx('radio-group')}>
              <RadioGroup
                id={'slate-editor-image'}
                name={'slate-editor-image'}
                onChange={(e) => {
                  const value = e.target.value as 'S' | 'M' | 'L' | 'FULL' | 'CUSTOM';
                  setImageType(value);
                  if (value === 'CUSTOM') {
                    setImageBlockStyle({
                      width: imageRef.current?.offsetWidth,
                      height: imageRef.current?.offsetHeight,
                    });
                    return;
                  }
                  setImageBlockStyle({
                    width: STYLE_WIDTH[value],
                    height: 'auto',
                  });
                }}
                value={imageType}
              >
                {[
                  { name: 'S', value: 'S' },
                  { name: 'M', value: 'M' },
                  { name: 'L', value: 'L' },
                  { name: 'Full', value: 'FULL' },
                  { name: <Icon name={'more_horiz_line'} color={COLORS.gray800} size={24} />, value: 'CUSTOM' },
                ].map(({ name, value }) => (
                  <RadioGroup.Option key={name + value} value={value}>
                    <RadioGroup.Consumer>
                      {({ selectValue }) => (
                        <div className={cx('radio', { active: selectValue === value })}>{name}</div>
                      )}
                    </RadioGroup.Consumer>
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>
            <div className={cx('divider')} />
            {imageType === 'CUSTOM' && (
              <>
                <div className={cx('custom-size')}>
                  {(
                    [
                      { name: '가로', value: 'width' },
                      { name: '세로', value: 'height' },
                    ] as Array<{ name: string; value: 'width' | 'height' }>
                  ).map(({ name, value }) => {
                    if (!imageRef.current) return;

                    const getStyle = {
                      width: imageRef.current?.offsetWidth,
                      height: imageRef.current?.offsetHeight,
                    };

                    const imageValue = element?.imageStyle![value] ?? getStyle[value];
                    const fieldValue = imageValue === 'auto' ? getStyle[value] : imageValue;

                    return (
                      <div className={cx('style-wrapper', { disabled: imageType !== 'CUSTOM' })}>
                        <Paragraph variant={'B3'}>{name} 크기</Paragraph>
                        <TextFieldBase
                          className={cx('style-text')}
                          size={'sm'}
                          maxLength={3}
                          value={fieldValue}
                          onChange={(e) => handleOnChange(e, value)}
                          disabled={imageType !== 'CUSTOM'}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={cx('divider')} />
              </>
            )}
            <div className={cx('custom-size')}>
              {(
                [
                  { icon: 'hori_align_left', value: 'left' },
                  { icon: 'hori_align_center', value: 'center' },
                  { icon: 'hori_align_right', value: 'right' },
                ] as Array<{ icon: IconTypes; value: EditorAlign }>
              ).map(({ icon, value }) => (
                <IconButton
                  className={cx('align_icon_button', { active: value === element?.imageStyle?.align ?? 'left' })}
                  type={'button'}
                  icon={icon}
                  size={24}
                  color={COLORS.gray800}
                  onClick={() => {
                    handleOnChangeAlign(value);
                  }}
                />
              ))}
            </div>
            <div className={cx('divider')} />
            <ImageHyperLinkButton element={element} pageList={pageList} />
            <div className={cx('divider')} />
            <div className={cx('custom-size')}>
              <Icon name={'corner_radius_24'} size={24} color={COLORS.gray800} />
              <TextFieldBase
                className={cx('border-text')}
                size={'sm'}
                maxLength={3}
                value={element?.imageStyle?.borderRadius ?? 0}
                onChange={(e) => handleOnChange(e, 'borderRadius')}
              />
            </div>
          </div>
        </Portal>
      )}
      <div
        className={cx('image-container')}
        style={{
          justifyContent: IMAGE_ALIGN[element.imageStyle?.align as EditorAlign] ?? 'flex-start',
        }}
      >
        <div className={cx('image-wrapper', { active: !readOnly && selected })} contentEditable={false}>
          {mounted && (
            <>
              <img
                src={cdnUrl}
                alt={element.label}
                style={{
                  display: 'block',
                  width: '100%',
                  height: element.imageStyle?.height ?? 'auto',
                  maxWidth: `${Math.min(760, element.imageStyle?.width ?? 760)}px`,
                  borderRadius: `${element.imageStyle?.borderRadius ?? 0}px`,
                  cursor: (isPreview || readOnly) && pageLink ? 'pointer' : 'default',
                }}
                onClick={handleClickImage}
                ref={imageRef}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageElement;
