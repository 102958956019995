import React, { useEffect, useState } from 'react';
import { hexRegex } from 'ui/util/reg';
import { IColorPickerBaseProps, IRgbaColor } from 'ui/common/colorPicker/types';
import { aToHex, hexToRgba } from 'ui/common/colorPicker/utils/convert';

interface IUseInputHandleProp {
  initText?: string;
  value: string;
  onBlur: () => void;
}

const useInputHandle = ({ initText = '', value, onBlur }: IUseInputHandleProp) => {
  const [inputText, setInputText] = useState<string>(initText);

  useEffect(() => {
    setInputText(value);
  }, [value]);

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter' && handleBlur();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, opt?: IColorPickerBaseProps<IRgbaColor>) => {
    if (hexRegex(e.target.value)) {
      if (opt) {
        const { onChange, color } = opt;
        onChange && onChange(hexToRgba(e.target.value + aToHex(color.a)));
      }
    }
    setInputText(e.target.value);
  };
  const handleBlur = () => onBlur();

  return {
    inputText,
    setInputText,
    handleKeydown,
    handleChange,
    handleBlur,
  };
};

export default useInputHandle;
