import { FC, useRef } from 'react';
import classnames from 'classnames/bind';

import style from './ToolbarButton.module.scss';
import Icon from '../../../icon/Icon';
import { BaseEditor, Editor } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import { DEFAULT_FONT_TYPE, FONT_TYPE_LIST, FONT_TYPE_TO_FONT_SIZE, MarkFormatType } from '../../textEditor.constant';
import { FontType, IFont } from '../../textEditor.type';
import { COLORS } from '../../../../constants/colors';

const cx = classnames.bind(style);

const toggleMark = (editor: BaseEditor & ReactEditor, format: MarkFormatType, bold: boolean) => {
  if (bold) {
    Editor.addMark(editor, format, true);
  } else {
    Editor.removeMark(editor, format);
  }
};

const FontTypeButton: FC<IFont> = ({ fontType, setFontType, setFontSize, setShowPop, showPop }) => {
  const editor = useSlate();
  const fontTypeRef = useRef<HTMLButtonElement>(null);

  const toggleFontType = (fontType: FontType = DEFAULT_FONT_TYPE) => {
    Editor.addMark(editor, 'fontType', fontType);
    Editor.addMark(editor, 'fontSize', FONT_TYPE_TO_FONT_SIZE[fontType]);
    setFontType(fontType);
    setFontSize(FONT_TYPE_TO_FONT_SIZE[fontType]);
  };

  useOnClickOutside(fontTypeRef, () => {
    showPop === 'FONT_TYPE' && setShowPop(null);
  });

  return (
    <button
      className={cx('container', 'select-fontType')}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => {
        setShowPop(showPop === 'FONT_TYPE' ? null : 'FONT_TYPE');
      }}
      type={'button'}
      ref={fontTypeRef}
    >
      {showPop === 'FONT_TYPE' && (
        <div className={cx('popover-wrapper', 'font-type')}>
          <ul className={cx('popover-container')}>
            {FONT_TYPE_LIST.map(({ title, value, bold }) => (
              <li
                className={cx({ active: value === fontType })}
                key={value}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleFontType(value);
                  toggleMark(editor, 'bold', bold);
                  setShowPop(null);
                }}
              >
                {title}
              </li>
            ))}
          </ul>
        </div>
      )}
      <span>{FONT_TYPE_LIST.filter(({ value }) => value === fontType).at(0)!.title}</span>
      <Icon name={'arrow_drop_down_filled'} size={24} color={COLORS.gray600} />
    </button>
  );
};
export default FontTypeButton;
