import { axios } from '../RequestClient';

export const postFileUpLoadV1 = async (
  formData: FormData
): Promise<{
  fileUrl: string;
  fileUid: string;
}> => {
  const { data } = await axios.post('/file/v1/upload', formData);

  return data;
};
