import { axios } from '../RequestClient';
import { IPostThumbnailPostListV1Request } from 'ui/interface/contents/board/getThumbnailPostListV1';

// 게시글 목록 조회
export const getThumbnailPostListV1 = async ({ pageable, filter }: IPostThumbnailPostListV1Request) => {
  const { data } = await axios.post('/article/v1/thumbnail/list', { pageable, filter });
  return data;
};

// 게시글 상세 불러오기
export const getArticleDetailV1 = async ({ prefix, articleSn }: { prefix?: string; articleSn: number }) => {
  try {
    const { data } = await axios.get(`/article/v2/${articleSn}`, {
      headers: {
        prefix,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};
