import React from 'react';
import classNames from 'classnames/bind';
import style from './Alpha.module.scss';
import { IHsvaColor } from '../../types';
import { IInteraction, Interactive } from '../interactive/Interactive';
import { clamp, round } from '../../utils';
import { hsvaToHslaString } from '../../utils/convert';
import { Pointer } from '../pointer/Pointer';
const cx = classNames.bind(style);

interface IProps {
  hsva: IHsvaColor;
  onChange: (newAlpha: { a: number }) => void;
}

export const Alpha = ({ hsva, onChange }: IProps): JSX.Element => {
  const handleMove = (interaction: IInteraction) => {
    onChange({ a: interaction.left });
  };

  const handleKey = (offset: IInteraction) => {
    onChange({ a: clamp(hsva.a + offset.left) });
  };

  const colorFrom = hsvaToHslaString(Object.assign({}, hsva, { a: 0 }));
  const colorTo = hsvaToHslaString(Object.assign({}, hsva, { a: 1 }));

  const gradientStyle = {
    backgroundImage: `linear-gradient(90deg, ${colorFrom}, ${colorTo})`,
  };

  const ariaValue = round(hsva.a * 100);

  return (
    <div className={cx('alphaArea')}>
      <div className={cx('alphaGradient')} style={gradientStyle} />
      <Interactive
        onMove={handleMove}
        onKey={handleKey}
        aria-label="Alpha"
        aria-valuetext={`${ariaValue}%`}
        aria-valuenow={ariaValue}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <Pointer className={cx('pointer')} left={hsva.a} />
      </Interactive>
    </div>
  );
};
