import { useMemo, ReactNode, InputHTMLAttributes, FC, useState, Consumer, useEffect } from 'react';
import { RadioGroupContext } from './RadioGroupContext';
import Option, { IRadioGroupOptionProps } from './Option';

interface IRadioGroupProps {
  children: ReactNode;
}

interface IRadioGroupChildren {
  Option: FC<IRadioGroupOptionProps>;
  Consumer: Consumer<any>;
}

const RadioGroup: React.FC<IRadioGroupProps & InputHTMLAttributes<HTMLInputElement>> & IRadioGroupChildren = (
  props
) => {
  const { value } = props;
  const [selectValue, setSelectValue] = useState(`${value}`);
  const values = useMemo(() => ({ ...props }), [props]);

  useEffect(() => {
    setSelectValue(`${value}`);
  }, [value]);

  return (
    <RadioGroupContext.Provider value={{ ...values, selectValue, setSelectValue }}>
      {props.children}
    </RadioGroupContext.Provider>
  );
};

RadioGroup.Option = Option;
RadioGroup.Consumer = RadioGroupContext.Consumer;

export default RadioGroup;
