import React, { useCallback, useEffect } from 'react';
import { Editor, Point, Range, Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { getInitialEditorText } from './textEditor.constant';
import { CustomElement, FontSizeType, FontType } from './textEditor.type';

interface IProps {
  fontSize: FontSizeType;
  fontType: FontType;
  activeButtonSetting: boolean;
  elementRef: React.RefObject<HTMLDivElement>;
}

const checkIsStartOfNode = (point: Point) => point.path[1] === 0 && point.offset === 0;
const checkIsEndOfNode = (after?: Point) => (after ? after.path[1] === 0 && after.offset === 0 : true);

const TextEditorEnterKeyInterceptor = ({ elementRef, activeButtonSetting, fontSize, fontType }: IProps) => {
  const editor = useSlate();
  const putNewNode = useCallback(
    (idx: number) => {
      if (!editor.selection) return;
      const [node] = Editor.parent(editor, editor.selection) as CustomElement[];
      const [leaf] = Editor.node(editor, editor.selection);
      return Transforms.insertNodes(
        editor,
        {
          type: 'paragraph',
          children: [getInitialEditorText({ ...leaf, fontSize, fontType, text: '' })],
        },
        { at: node.type === 'image' ? [idx + 1] : [idx] }
      );
    },
    [editor, fontSize, fontType]
  );

  const putCursor = useCallback(
    (idx: number) => {
      Transforms.select(editor, {
        anchor: { path: [idx, 0], offset: 0 },
        focus: { path: [idx, 0], offset: 0 },
      });
    },
    [editor]
  );

  useEffect(() => {
    const putNewNodeBeforeNode = (selection: Range) => {
      const newNodeIdx = selection.anchor.path[0];
      putNewNode(newNodeIdx);
      putCursor(newNodeIdx + 1);
    };

    const putNewNodeAfterNode = (after?: Point) => {
      const newNodeIdx = after ? after.path[0] : editor.children.length;
      putNewNode(newNodeIdx);
      putCursor(newNodeIdx);
    };

    const enterAndSplit = (selection: Range) => {
      if (activeButtonSetting) {
        Transforms.insertNodes(
          editor,
          { type: 'paragraph', children: [getInitialEditorText()] },
          { at: [selection.anchor.path[0] + 1] }
        );
        return putCursor(selection.anchor.path[0] + 1);
      }

      const after = Editor.after(editor, selection, { unit: 'character' });
      if (checkIsStartOfNode(selection.anchor)) return putNewNodeBeforeNode(selection);
      if (checkIsEndOfNode(after)) return putNewNodeAfterNode(after);
      Transforms.splitNodes(editor);
      putCursor(selection.anchor.path[0] + 1);
    };

    const handleKeydownEnter = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;
      const { selection } = editor;
      if (!selection) return;

      e.preventDefault();
      e.stopPropagation();
      enterAndSplit(selection);
    };

    const ref = elementRef;
    ref.current?.addEventListener('keydown', handleKeydownEnter);
    return () => ref.current?.removeEventListener('keydown', handleKeydownEnter);
  }, [elementRef, editor, putNewNode, putCursor, activeButtonSetting]);

  return <></>;
};

export default TextEditorEnterKeyInterceptor;
