// Created by kdw0601 on 2022-12-05
import styles from './Tab.module.scss';
import classNames from 'classnames/bind';
import ITabOption, { ISwipeOption } from './define/ITabOption';
import { SwipeArrow } from '../button/IconButtonVariants/SwipeArrow/SwipeArrow';
import useSwipe from './useSwipe';

const cx = classNames.bind(styles);

interface IProps<OptionValues extends string = string> {
  type?: 'primary' | 'secondary' | 'label' | 'card';
  options: ITabOption<OptionValues>[];
  className?: string;
  onChange?: (val: OptionValues) => void;
  value: OptionValues;
  swipeOption?: ISwipeOption;
}

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3603038251/Tab}
 */
const Tab = <OptionValues extends string>(props: IProps<OptionValues>) => {
  const { type = 'primary', className, options, onChange, value: currentValue, swipeOption } = props;
  const {
    enabled: swipeEnabled = false,
    variant: swipeVariant = 'blur',
    size: swipeSize = 'lg',
  } = swipeOption || { enabled: false };
  const { targetRef, showBackArrow, showNextArrow, scrollToRight, scrollToLeft, scrollPosition } =
    useSwipe(swipeEnabled);

  const handleChange = (val: OptionValues) => {
    if (currentValue === val) return;
    onChange && onChange(val);
  };

  return (
    <>
      {swipeEnabled && showBackArrow && (
        <SwipeArrow
          className={cx('swipe-arrow', 'back')}
          variant={swipeVariant}
          size={swipeSize}
          arrowType={'back'}
          onClick={() => scrollToLeft()}
        />
      )}
      <div className={cx('container', type, className)} ref={targetRef} style={{ left: `${scrollPosition}px` }}>
        {options
          .filter(({ hidden }) => !hidden)
          .map(({ label, value, disabled, right }) => (
            <button
              type={'button'}
              className={cx('option', value === currentValue && 'active')}
              key={value}
              onClick={() => handleChange(value)}
              disabled={disabled}
            >
              {label}
              {type === 'card' && right !== undefined && <div className={cx('right')}>{right}</div>}
            </button>
          ))}
      </div>
      {swipeEnabled && showNextArrow && (
        <SwipeArrow
          className={cx('swipe-arrow', 'next')}
          variant={swipeVariant}
          size={swipeSize}
          arrowType={'next'}
          onClick={() => scrollToRight()}
        />
      )}
    </>
  );
};

export default Tab;
