export const STYLE_WIDTH = {
  S: 240,
  M: 400,
  L: 640,
  FULL: 760,
} as const;

export const IMAGE_ALIGN = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;
