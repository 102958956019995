import React from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import classnames from 'classnames/bind';

import style from './ToolbarButton.module.scss';
import EditorIcon from '../../EditorIcon';
import { BaseEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import type { BlockFormatType, EditorAlign, IElementAlign } from '../../textEditor.type';

const cx = classnames.bind(style);

const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];
const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = (editor: BaseEditor & ReactEditor, format: BlockFormatType, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any)[blockType] === format,
    })
  );

  return !!match;
};

const toggleBlock = (editor: BaseEditor & ReactEditor, format: BlockFormatType) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : (format as EditorAlign),
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, setPlaceHolderAlign }: { format: BlockFormatType } & IElementAlign) => {
  const editor = useSlate();

  const active = isBlockActive(editor, format);

  return (
    <button
      className={cx('container', { active })}
      type={'button'}
      onMouseDown={(event) => {
        event.preventDefault();

        //TODO: FORMAT 다른게 들어오면 함수 분리하기
        setPlaceHolderAlign && TEXT_ALIGN_TYPES.includes(format) && setPlaceHolderAlign(format as EditorAlign);
        toggleBlock(editor, format);
      }}
    >
      <EditorIcon name={format} />
    </button>
  );
};

export default BlockButton;
