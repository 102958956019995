import React, { useState, useEffect } from 'react';

interface IUseAbsolutePositionProps {
  ref: React.RefObject<any>;
  toolbarWidth: number;
}

// 텍스트 에디터 툴바의 포지션이 오른쪽 끝으로 넘어가지 않게 계산하는 hook 입니다.
// FIXME 최적화 및 공통화 필요합니다.
const useToolbarAbsolutePosition = ({ ref, toolbarWidth }: IUseAbsolutePositionProps) => {
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const OFFSET_TOP = -58;

  const handleResize = () => {
    const contentsEl = ref?.current;

    if (contentsEl) {
      const contentsRect = contentsEl.getBoundingClientRect();
      const top = contentsRect.top + window.pageYOffset + OFFSET_TOP;
      const left = contentsRect.left;
      const windowWidth = window.innerWidth;
      const isOver = left + toolbarWidth > windowWidth - 50;
      const newLeft = left - (left + toolbarWidth - windowWidth) - 50;

      setPosition({
        top,
        left: isOver ? newLeft : left,
      });
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return position;
};

export default useToolbarAbsolutePosition;
