import classnames from 'classnames/bind';
import style from './ToolbarButton.module.scss';
import { ReactEditor, useSlate } from 'slate-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import useOnClickOutside from '../../../../hook/useOnClickOutside';
import Icon from '../../../icon/Icon';
import { BaseEditor, Editor } from 'slate';
import type { ShowPopType } from '../../textEditor.constant';
import { COLORS } from '../../../../constants/colors';
import { FONT_FAMILY, FontFamilyType } from '../../../../constants/font.constant';

const cx = classnames.bind(style);

const getFontFamily = (editor: BaseEditor & ReactEditor, format: 'fontFamily'): string | null => {
  const marks = Editor.marks(editor);

  return marks ? (marks[format] as string) : null;
};

const toggleFontFamily = (editor: BaseEditor & ReactEditor, format: 'fontFamily', fontType: FontFamilyType) => {
  Editor.addMark(editor, format, fontType);
};

interface IProps {
  setShowPop: (v: ShowPopType) => void;
  showPop: ShowPopType;
}

const FontFamilyButton: FC<IProps> = ({ showPop, setShowPop }) => {
  const editor = useSlate();
  const fontFamilyRef = useRef<HTMLButtonElement>(null);
  const [fontFamily, setFontFamily] = useState('Pretendard');

  useOnClickOutside(fontFamilyRef, () => {
    showPop === 'FONT_FAMILY' && setShowPop(null);
  });

  /* Editor Cursor fontSize */
  useEffect(() => {
    setFontFamily(getFontFamily(editor, 'fontFamily') ?? 'Pretendard');
  }, [editor.selection]);

  return (
    <button
      className={cx('container', 'select-font')}
      onMouseDown={(e) => e.preventDefault()}
      onClick={() => {
        setShowPop(showPop === 'FONT_FAMILY' ? null : 'FONT_FAMILY');
      }}
      type={'button'}
      ref={fontFamilyRef}
    >
      {showPop === 'FONT_FAMILY' && (
        <div className={cx('popover-wrapper', 'font-family')}>
          <ul className={cx('popover-container')}>
            {FONT_FAMILY.map((font_family) => (
              <li
                className={cx({ active: fontFamily === font_family })}
                key={fontFamily}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setFontFamily(font_family);
                  toggleFontFamily(editor, 'fontFamily', font_family as FontFamilyType);
                  setShowPop(null);
                }}
              >
                {font_family}
              </li>
            ))}
          </ul>
        </div>
      )}
      <span>{fontFamily}</span>
      <Icon name={'arrow_drop_down_filled'} size={24} color={COLORS.gray600} />
    </button>
  );
};

export default FontFamilyButton;
