import React, { startTransition, useEffect, useRef, useState } from 'react';
import { Editor } from 'slate';
import { hexToRgba, rgbaToHex } from '../../../colorPicker/utils/convert';
import { useSlate } from 'slate-react';
import style from './ToolbarButton.module.scss';
import classnames from 'classnames/bind';
import ColorPicker from '../../../colorPicker/ColorPicker';
import { IRgbaColor } from '../../../colorPicker/types';
import useOnClickOutside from '../../../../hook/useOnClickOutside';

const cx = classnames.bind(style);

interface IProps {
  format: 'color' | 'highlight';
  initialColor: string;
  setShowPop: (a: null) => void;
}

const SlateColorPicker = ({ format, initialColor, setShowPop }: IProps) => {
  const editor = useSlate();
  const [color, setColor] = useState<string>(initialColor);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(colorPickerRef, () => {
    setShowPop(null);
  });

  useEffect(() => {
    Editor.addMark(editor, format, color);
  }, [color]);

  const handleChangeColor = (color: IRgbaColor) => {
    startTransition(() => setColor(rgbaToHex(color)));
  };

  return (
    <div ref={colorPickerRef} className={cx('color-picker-modal')} onClick={(e) => e.stopPropagation()}>
      <ColorPicker color={hexToRgba(color)} onChange={handleChangeColor} />
    </div>
  );
};

export default SlateColorPicker;
