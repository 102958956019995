import { useSlate } from 'slate-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Transforms } from 'slate';
import classnames from 'classnames/bind';
import style from './ImageHyperLinkButton.module.scss';
import { ILNBType, ILNBTypes, ISingleLNBType, URL_TYPE } from '../../../interface/header/IMenu';
import usePageDropdown from '../../../hook/editor/usePageDropdown';
import useOnClickOutside from '../../../hook/useOnClickOutside';
import { delay } from '../../../util/time';
import EditorIcon from '../EditorIcon';
import Tab from '../../tab/Tab';
import Button from '../../button/Button';
import TextFieldBase from '../../textfield/TextFieldBase';
import { CustomElement } from '../textEditor.type';

const cx = classnames.bind(style);

interface IProps {
  element?: CustomElement;
  pageList: ILNBType<ILNBTypes>[];
}

const ImageHyperLinkButton: FC<IProps> = ({ element, pageList = [] }) => {
  const editor = useSlate();
  const [showPop, setShowPop] = useState<boolean>(false);
  const [url, setUrl] = useState('');
  const [isValidUrl, setIsValidUrl] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [type, setType] = useState<'PAGE' | 'URL'>('PAGE');
  const { PageDropdown, setShowDropdown, tempPage, setTempPage } = usePageDropdown({ pageList });
  const isActive = element?.pageLink!!;

  useEffect(() => {
    setIsValidUrl(!!url);
  }, [url]);

  useOnClickOutside(ref, (e) => {
    setShowPop(false);
    setUrl('');
    setShowDropdown(false);
    setTempPage(null);
    setType('PAGE');
  });

  useEffect(() => {
    (async () => {
      if (showPop && type === 'URL' && inputRef.current) {
        const input = inputRef.current;
        await delay(0);
        input.focus();
        input.setSelectionRange(input.value.length, input.value.length);
      }
    })();
  }, [showPop, type]);

  useEffect(() => {
    if (!isActive) return;
    const { type, lnb } = element?.pageLink!;

    switch (type) {
      case 'PAGE':
        setTempPage({ ...lnb });
        break;
      case 'URL':
        setTempPage({
          title: '',
          formType: 'SINGLE',
          page: { urlType: URL_TYPE.EXTERNAL, url: lnb.page.url },
        } as ILNBType<ISingleLNBType>);

        break;
    }
    setType(type!);
    setUrl(lnb.page.url);
  }, [isActive]);

  return (
    <button
      className={cx('icon-button', 'hyper', isActive && 'active')}
      type={'button'}
      onMouseDown={(e) => {
        e.stopPropagation();
        if (isActive) {
          setShowPop(true);
        } else {
          setShowPop((prev) => !prev);
        }
      }}
    >
      <EditorIcon name={'link'} />

      {showPop && (
        <div className={cx('hyper-wrapper')} onMouseDown={(e) => e.stopPropagation()} ref={ref}>
          <Tab
            className={cx('tab')}
            type={'secondary'}
            onChange={(v) => setType(v)}
            options={[
              { value: 'PAGE', label: '페이지' },
              { value: 'URL', label: 'URL' },
            ]}
            value={type}
          />
          <div className={cx('bottom')}>
            {type === 'PAGE' ? (
              <div className={cx('dropdown-container')}>
                <PageDropdown />
                <Button
                  className={cx('save')}
                  onClick={() => {
                    if (tempPage) {
                      Transforms.setNodes(editor, {
                        ...element,
                        pageLink: { type, lnb: tempPage },
                      });
                    } else {
                      Transforms.unsetNodes(editor, 'pageLink');
                    }
                    setTempPage(null);
                    setShowPop(false);
                  }}
                >
                  저장
                </Button>
              </div>
            ) : (
              <div className={cx('url-wrapper')}>
                <TextFieldBase
                  size={'md'}
                  placeholder={'URL을 입력하세요.'}
                  className={cx('input')}
                  value={url}
                  onChange={(e) => {
                    if (!e.target.value) {
                      Transforms.unsetNodes(editor, 'pageLink');
                    }
                    setUrl(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && isValidUrl) {
                      let addMarkUrl = url;
                      if (!/^https?:\/\//i.test(addMarkUrl)) {
                        addMarkUrl = 'https://' + url;
                      }
                      Transforms.setNodes(editor, {
                        ...element,
                        pageLink: {
                          type,
                          lnb: {
                            page: {
                              url: addMarkUrl,
                              urlType: URL_TYPE.EXTERNAL,
                            },
                          } as ILNBType<ISingleLNBType>,
                        },
                      });
                      setShowPop(false);
                      setUrl('');
                    }
                  }}
                  ref={inputRef}
                />
                <Button
                  className={cx('url-button')}
                  onClick={() => {
                    if (!isValidUrl) return;

                    let addMarkUrl = url;
                    if (!/^https?:\/\//i.test(addMarkUrl)) {
                      addMarkUrl = 'https://' + url;
                    }

                    Transforms.setNodes(editor, {
                      ...element,
                      pageLink: {
                        type,
                        lnb: {
                          page: {
                            url: addMarkUrl,
                            urlType: URL_TYPE.EXTERNAL,
                          },
                        } as ILNBType<ISingleLNBType>,
                      },
                    });

                    setShowPop(false);
                    setUrl('');
                  }}
                >
                  저장
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </button>
  );
};

export default ImageHyperLinkButton;
