import { ButtonHTMLAttributes } from 'react';
import styles from './Stepper.module.scss';
import classNames from 'classnames/bind';
import IconElement from '../../../icon/Icon';

const cx = classNames.bind(styles);

interface IProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  stepperType: 'minus' | 'plus';
  isFocused?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const Stepper = ({ stepperType, size = 'sm', isFocused, disabled, className, ...htmlAttr }: IProps) => {
  return (
    <button className={cx('stepper', size, { isFocused: isFocused }, className)} disabled={disabled} {...htmlAttr}>
      <IconElement
        name={stepperType === 'minus' ? 'remove_line' : 'add_line'}
        className={cx('stepper__svg')}
        size={18}
      />
    </button>
  );
};

/* EXAMPLE
 * <IconButton.Stepper stepperType="minus" size="lg" />
 * <IconButton.Stepper stepperType="minus" size="md" />
 * <IconButton.Stepper stepperType="minus" size="sm" />
 * <IconButton.Stepper stepperType="plus" isFocused />
 * <IconButton.Stepper stepperType="plus" disabled />
 * <IconButton.Stepper stepperType="plus" isFocused disabled />
 */

/* NOTE
 * minus는 빼기, plus는 더하기
 * size는 sm, md, lg 중 하나로, 기본적으로 아무것도 할당해주지 않을경우 sm으로 적용된다.
 * isFocused는 Stepper가 포커스된 상태인지 (구체적으로 어떤 상황에 적용되는지는 모르겠지만 디자인키트에 존재)
 * disabled는 Stepper를 비활성화시키는 것. isFocused와 disabled가 함께 설정되어 있다면 isFocused가 씹히고 disabled 스타일만 남는다.
 * className을 props로 받았기 때문에, 부모가 Stepper의 스타일링을 할 수 있다. 특히, 포지션 지정할 때 이를 활용하도록 한다.
 */
