import { VIEW_MODE, ViewModeType } from '../interface/common/ViewMode';
// 반응형 폰트 정책
// https://www.figma.com/file/gd84ZPepsxsLx6TJoL04D6/%5B%EB%94%94%EC%9E%90%EC%9D%B8%5D-ATS-%EC%B1%84%EC%9A%A9%EC%82%AC%EC%9D%B4%ED%8A%B8-%EB%B9%8C%EB%8D%94_v1.0_%EC%8B%A0%EA%B7%9C%EC%B6%94%EA%B0%80?node-id=1884%3A75010&t=L7IGv0LIqMW6WE9j-1
export const convertFontSize = (fontSize: number, viewMode?: ViewModeType) => {
  switch (viewMode) {
    case VIEW_MODE.TABLET:
      return fontSize >= 48 ? 48 : fontSize;
    case VIEW_MODE.MOBILE:
      if (fontSize >= 32) return 32;
      if (fontSize >= 24) return 24;
      if (fontSize >= 20) return 20;
      if (fontSize >= 16) return 16;
    default:
      return fontSize;
  }
};

export const convertLineHeight = (fontSize: number) => {
  if (fontSize >= 64) return 84;
  if (fontSize >= 56) return 72;
  if (fontSize >= 48) return 62;
  if (fontSize >= 38) return 52;
  if (fontSize >= 34) return 48;
  if (fontSize >= 32) return 46;
  if (fontSize >= 28) return 40;
  if (fontSize >= 26) return 38;
  if (fontSize >= 24) return 36;
  if (fontSize >= 22) return 34;
  if (fontSize >= 20) return 32;
  if (fontSize >= 18) return 30;
  if (fontSize >= 16) return 26;
};
