import { APPS } from '../../constants/app.constant';
import { APP_TYPE } from '../../util/app';
import { editorAxios } from '../requestClient/editor';
import { recruiterAxios } from '../requestClient/recruiter';

// DEPRECATED :: S3 CDN 전체 전환에 따라 사용하지 않음
export const postFileCdnUpLoadV1 = async (fileUid: string): Promise<{ fileUrl: string }> => {
  const axios = APP_TYPE === APPS.EDITOR ? editorAxios : recruiterAxios;
  const { data } = await axios.get(`/file/v1/${fileUid}/cdn`);
  return data;
};
